@import "./global/mediaquery.scss";

.ch-lista-vino-bg {
  margin-top: 230px;
  height: 680px;
  img {
    height: 680px;
    object-fit: cover;
    object-position: center;
    position: absolute;

    @include respond-to($phoneLarge) {
      margin-top: -2px;
    }
  }
  @include respond-to($phoneLarge) {
    height: auto;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-top: 0;
    }
    img {
      position: relative;
      height: auto;
      order: 2;
    }
    .container,
    .row,
    .col-12 {
      padding: 0;
    }
  }
}

.ch-lista-vino--box {
  min-height: 460px;
  background-color: #3a0624;
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  transform: translateY(-114px);

  @include respond-to($phoneLarge) {
    transform: none;
    padding-top: 60px;
    padding-left: 35px;

    padding-right: 35px;
    padding-bottom: 35px;
  }

  h3 {
    font-family: "Playfair Display", serif;

    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

    @include respond-to($phoneLarge) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    @include respond-to($phoneLarge) {
      font-size: 18px;
    }
  }
  button {
    background: none;
    border: solid 2px #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: absolute;
    bottom: 40px;
    left: 40px;
    @include respond-to($tablet-landscape) {
      position: relative;
      left: auto;
      margin-top: 70px;
    }
    @include respond-to($phoneLarge) {
      width: 100%;
      position: relative;
      left: auto;
      bottom: auto;
      margin-top: 55px;
    }
  }
}

.playfair {
  font-family: "Playfair Display", serif;
  margin-bottom: 40px;
  font-weight: bold;
}
