@import "../global/variables.scss";
@import "../global/typography.scss";
@import "../global/mediaquery.scss";

.content404hero {
  margin-bottom: -90px;
  @media (max-width: 768px) {
    margin-bottom: 0;
    background-position: 60% !important;
  }
}
.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      to top,
      #{fade-out(#000, 0.8)} 0%,
      #{fade-out(#000, 0.9)} 15%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("./img/hero-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  .contentIntro404 {
    text-align: center;
    max-width: 470px;
    h2 {
      font-family: "Playfair Display", serif;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      font-size: 58px;
      span {
        font-size: 36px;
        font-family: "Playfair Display", serif;
      }
    }
    @media (max-width: 768px) {
      h2 {
        font-size: 40px;
      }
    }
  }
}
