@import "../global/variables.scss";
@import "../global/typography.scss";
@import "../global/mediaquery.scss";

footer {
  background-image: url(../img/back.svg);
  background-position: center;
  background-size: cover;
  min-height: 540px;
  padding-bottom: 60px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  @include respond-to($tablet-start) {
    background-image: none;
    background: #272121;
  }
  @include respond-to($tablet-start2) {
    .footerMobile {
      display: none;
    }
    .footerDesktop {
      display: flex;
      .footerSx {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        svg {
          margin-bottom: 10px;
        }
        span {
          width: 247px;
          height: 16px;
          font-family: "Playfair Display", serif;
          font-size: 18px;
          font-weight: normal;
          //font-style: italic;
          font-stretch: normal;
          line-height: 0.67;
          letter-spacing: normal;
          color: $white;
        }
        .copyright {
          width: 387px;
          height: 71px;
          margin-top: 55px;
          font-family: "Rubik", sans-serif;
          font-size: 10px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.8;
          letter-spacing: normal;
          color: $white;
          li {
            white-space: nowrap;
            color: white !important;
          }
        }
      }
      .footerCt {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .socialMenu {
          text-align: center;
          a {
            svg {
              width: 36px;
              height: 36px;
              margin: auto 12px;
            }
          }
        }
      }
      .footerDx {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .linkContatti {
          a {
            font-family: "Rubik", sans-serif;
            display: block;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $white;
          }
        }
        .paginePrivacy {
          margin-top: 55px;
          transform: translateY(-20px);
          a {
            font-family: "Rubik", sans-serif;
            font-size: 10px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: right;
            color: $white;
            display: block;
          }
        }
      }
    }
  }
  @include respond-to($tablet-start) {
    min-height: 100%;
    .footerDesktop {
      display: none;
    }
    .footerMobile {
      padding-top: 0;
      .row:first-child {
        display: flex;
        flex-direction: row;
      }
      .row:nth-child(2) {
        display: flex;
        flex-direction: column;
      }
      .footerSx {
        margin-bottom: 83px;
        .paginePrivacy {
          transform: none;
          a {
            font-family: "Rubik", sans-serif;
            display: block;
            font-size: 14px;
            margin: 0 0 23px 0;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $white;
          }
        }
      }
      .footerCt {
        .socialMenu {
          text-align: right;
          a {
            svg {
              width: 36px;
              height: 36px;
              margin-left: 12px;
              margin-bottom: 40px;
            }
          }
        }
        .linkContatti {
          a {
            line-height: 1.8;
            font-family: "Rubik", sans-serif;
            display: block;
            text-align: right;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $white;
          }
        }
      }
      .footerDx {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        span {
          width: 247px;
          height: 16px;
          font-family: "Playfair Display", serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.67;
          letter-spacing: normal;
          color: $white;
        }
        .copyright {
          width: 387px;
          height: 71px;
          margin-top: 55px;
          font-family: "Rubik", sans-serif;
          font-size: 10px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.8;
          letter-spacing: normal;
          color: $white;
        }
      }
    }
  }
}

.footerDx {
  @include respond-to($phoneLarge) {
    box-sizing: border-box;
    .copyright {
      width: 100% !important;
      li {
        color: white !important;
      }
    }
  }
}

.wrapper-logo-mobile {
  display: none;
  @include respond-to($tablet-start) {
    display: flex;
    svg {
      transform: translateY(-73px);
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.socialMenu {
  @include respond-to($phoneLarge) {
    transform: translateY(-14px);
  }
}

.linkContatti {
  @include respond-to($phoneLarge) {
    transform: translateY(-25px);
  }
}

.european-logo {
  display: flex;
  align-items: center;
  margin-top: 30px;
  img {
    width: 90px;
    margin-right: 17px;
    @include respond-to($phoneLarge) {
      width: 55px;
    }
  }
  p {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 0;
    @include respond-to($phoneLarge) {
      font-size: 8px;
    }
  }
}
