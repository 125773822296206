$brown: #272121;

$black: #000;

$lightBlack: #2a455c;

$gold: #b48e52;
$dark: #0d0d0d;
$darkGold: #b48e52;

$blu: #154278;
$yellow: #ffd667;
$gray: #666666;
$green: #75aa38;
$red: #dc2933;
$lightGrey: #ededed;
$white: #fff;

@import url("https://fonts.googleapis.com/css?family=Prompt:400,500,600");

body * {
  font-family: "Rubik", sans-serif;
}

@font-face {
  font-family: Roboto Slab Bold;
  src: url("../fonts/RobotoSlab-Bold.ttf");
}

@font-face {
  font-family: Roboto Slab;
  src: url("../fonts/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: Roboto Slab Light;
  src: url("../fonts/RobotoSlab-Light.ttf");
}

$family: "Prompt", sans-serif;

@mixin hFont {
  font-size: 30px;
  font-weight: 500;
  font-family: $family;
  margin-top: 0;
}

@mixin pfont {
  font-weight: 400;
  font-size: 14px;
}

@mixin pBold {
  font-weight: 600;
  font-size: 16px;
}
