@import "./global/mediaquery.scss";

.ct-caratteristiche {
  transform: translateY(-150px);
  padding-bottom: 100px;
  @include respond-to($phoneLarge) {
    padding: 0;
    transform: none;
    .col-12 {
      padding: 0;
    }
  }
}

.ch-product-img {
  img {
    width: 200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}

.ch-caratteristiche {
  background: white;

  &-inner {
    padding-bottom: 41px;
    padding-left: 41px;
    padding-right: 41px;
  }
  &--head {
    height: 60px;
    background-color: #ffd667;
    display: flex;
    align-items: center;
    align-content: center;
    svg {
      margin-left: 40px;
    }
  }
  &-desc {
    h1 {
      color: black;
      font-family: "Playfair Display", serif;
      font-size: 36px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      margin-top: 56px;
      letter-spacing: normal;
    }
    h2 {
      font-family: "Rubik", sans-serif;

      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #1f1f1f;
    }
    p {
      font-family: "Rubik", sans-serif;

      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: black;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}

.ch-caratteristiche--row {
  position: relative;
  padding-bottom: 12px;
  margin-top: 12px;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  svg {
    transition: 0.3s ease;
  }
  &.is-open {
    svg {
      transform: rotate(90deg);
    }
  }
}

.ch-accordion {
  &--title {
    font-family: "Rubik", sans-serif;

    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1f1f;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0;
    align-items: center;
  }
  &--content {
    font-family: "Rubik", sans-serif;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    padding-bottom: 30px;
    padding-top: 15px;
  }
}

.ch-carattertistiche-btn {
  display: flex;
  justify-content: flex-start;
  margin-top: 38px;
  @include respond-to($phoneLarge) {
    flex-direction: column;
  }
  button {
    width: 170px;
    height: 42px;
    border: solid 2px #1f1f1f;
    font-family: "Rubik", sans-serif;

    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
    transition: 0.3s ease;
    @include respond-to($phoneLarge) {
      width: 100%;
    }
    &.picture-btn {
      width: 112px;
      margin-left: 29px;
      @include respond-to($phoneLarge) {
        width: 100%;
        margin-top: 18px;
        margin-left: 0;
      }
    }
    &:hover {
      background: #1f1f1f;
      color: white;
    }
  }
}
