@import "./global/mediaquery.scss";

.ct-related {
  margin-top: 106px;
  @include respond-to($tablet-start) {
    margin-bottom: 100px;
  }
  h6 {
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 26px;
    @include respond-to($tablet-start) {
      font-size: 18px;
    }
  }
  p {
    font-family: "Rubik", sans-serif;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 90px;
    @include respond-to($tablet-start) {
      font-size: 14px;
    }
    &.text-left {
      margin-bottom: 84px;
    }
  }
}
