@import "./global/mediaquery.scss";

.grid-prodotti {
  margin-top: 80px;
  @include respond-to($phoneLarge) {
    margin-bottom: 100px;
  }
}
.ch-prodotto-item {
  background-color: #fcf7f2;
  padding-top: 0;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 60px;
  display: flex;
  width: 247px;
  height: 223px;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;

  .ch-prodotto-item--desc {
    width: 100px;
    text-align: left;
    p {
      text-align: left;
    }
    @include respond-to($phoneLarge) {
      text-align: left;
      p {
        text-align: left;
      }
    }
  }

  @include respond-to($phoneLarge) {
    margin-left: auto;
    margin-right: auto;

    width: 297px;

    height: 258px;
  }
  &:before {
    content: "";
    width: 247px;
    height: 12px;
    position: absolute;
    right: -1px;
    top: 0;
    background-color: #ffd667;
    @include respond-to($phoneLarge) {
      width: 297px;
    }
  }
  h3 {
    font-family: "Playfair Display", serif;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    padding-top: 29px;
    line-height: 21px;
    @include respond-to($phoneLarge) {
      padding-top: 44px;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    white-space: nowrap;
    color: black;
  }
  img {
    width: 89px;
    -webkit-transform: translateY(24px);
    transform: translateY(25px);
    @include respond-to($phoneLarge) {
      width: 96px;
      -webkit-transform: translateY(24px);
      transform: translateY(42px);
    }
  }
}

.ch-prodotto-item--arrow {
  position: absolute;
  left: 30px;
  bottom: 30px;
}

.ch-product-img {
  @include respond-to($tablet-start) {
    padding-top: 60px;
    padding-bottom: 80px;
    background-color: #f8f2ed;
  }
}
