.ct-pacchetti {
}

.contact-slider {
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 999;
    &.slick-prev {
      left: 0;
    }
    &.slick-next {
      right: 0;
    }
  }
  @include respond-to($phoneLarge) {
    .slick-slide {
      height: 293px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.ct-dark {
  background-color: #393131;
  padding-top: 90px;
  padding-bottom: 90px;
  h5 {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    font-weight: bold;
  }
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 26px;
  }
}
.ct-indicazioni {
  padding-top: 100px;
  padding-bottom: 150px;
  h2 {
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 35px;
  }
  h4 {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1f1f1f;
  }
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: black;
    margin-bottom: 40px;
  }
}
.ch-popup {
  width: 100vw;
  height: 100%;

  z-index: 9999999999;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  display: flex;
  position: absolute;
  display: none;
  &:before {
    content: '';
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    filter: blur(100px);
  }
}
.ch-popup-inner {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 38px;
  padding-right: 30px;
  max-width: 890px;
  background-color: #fcf7f2;
  z-index: 9999;
}

.close-popup {
}

.ch-form--header {
  h2 {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1f1f;
  }
}

.ch-wrapper-inpu {
  input {
    margin-bottom: 50px;
    width: 100%;
    background: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;

    border-bottom: solid 1px #979797;
    color: white;
  }
  label {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1f1f1f;
  }
  textarea {
    border: solid 1px #979797;
    background: none;
  }
}

.slide-container {
  position: relative;
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-35px);
    left: 98px;
    cursor: pointer;
    @include respond-to($phoneLarge) {
      left: 20px;
    }
    svg {
      width: 64px;
      height: 64px;
    }
    &.next {
      left: auto;
      right: 98px;
      @include respond-to($phoneLarge) {
        right: 20px;
      }
    }
  }
}

.ch-map-contatti {
  margin-bottom: 160px;
  iframe {
    position: absolute;
    @include respond-to($tablet-start) {
      position: relative;
      height: 316px;
      margin-top: -20px;
    }
  }
}

.ct-form {
  background-color: #fcf7f2;
  padding-bottom: 200px;

  @include respond-to($tablet-start) {
    padding-bottom: 20px;
    margin-bottom: 30px;

    .container {
      padding: 0;
      .col-12 {
        padding: 0;
      }
    }
  }
  form {
    background-color: #3a0624;
    padding-top: 83px;
    padding-left: 46px;
    padding-right: 46px;
    padding-bottom: 46px;
    transform: translateY(-153px);
    @include respond-to($tablet-start) {
      transform: none;
      padding-left: 29px;
      padding-right: 29px;
      padding-top: 30px;
    }
    ::-webkit-input-placeholder {
      /* Edge */
      font-family: Rubik;
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: Rubik;
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff;
    }

    ::placeholder {
      font-family: Rubik;
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff;
    }

    h1 {
      font-family: PlayfairDisplay;
      font-size: 36px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 30px;
      @include respond-to($tablet-start) {
        font-size: 24px;
      }
    }
    .ch-wrapper-inpu {
      label {
        color: white;
      }
    }
    button {
      width: 160px;
      height: 54px;
      border: solid 2px #ffffff;
      font-family: Rubik;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      background: none;
      margin-top: 30px;
      @include respond-to($tablet-start) {
        width: 100%;
      }
    }
    textarea {
      width: 100%;
      height: 241px;
    }
  }
}

.ch-form-contacts {
  padding-top: 69px;
  padding-left: 40px;
  @include respond-to($tablet-start) {
    padding-bottom: 50px;
  }

  p {
    font-family: Rubik;
    font-size: 24px;

    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #1f1f1f;
    a {
      color: #1f1f1f;
    }
    @include respond-to($tablet-start) {
      font-size: 18px;
    }

    strong {
      font-weight: 500;
    }
  }
}

.ch-map-contatti {
  @include respond-to($tablet-start) {
    flex-wrap: wrap;
    margin-bottom: 40px;
    height: auto;
  }
  .container {
    @include respond-to($tablet-start) {
      padding: 0;
    }
  }
  .d-tablet {
    display: none;
    @include respond-to($tablet-start) {
      display: block;
      margin-top: 40px;
    }
  }
  .d-desktop {
    display: block;
    @include respond-to($tablet-start) {
      display: none;
    }
  }
  .containerMobile {
    transform: translateY(-209px);
    @include respond-to($tablet-start) {
      transform: none;
      .container {
        padding: 0;
      }
    }
    .boxesIniziali {
      padding-right: 30px;
      justify-content: flex-start;
      @include respond-to($tablet-start) {
        height: 317px !important;
        min-height: auto;
        margin-bottom: 0;
        svg {
        }
      }
    }
    .textBox {
      position: absolute;
      bottom: 40%;
      @include respond-to($tablet-start) {
        font-size: 16px !important;
        position: relative;
        margin-top: 40px;
        bottom: auto;
        width: 100% !important;
      }
    }
    .line {
      margin-top: 46px;
    }
    .contentBoxesOut .boxesIniziali .boxIn {
      padding-top: 0;
      justify-content: flex-start;
    }
  }
}

.ch-map-contatti {
  @include respond-to($phoneLarge) {
    iframe {
      order: 3 !important;
    }
  }
  #bgGrapsOut {
    @include respond-to($phoneLarge) {
      &:hover {
        background-color: #272121;
      }
    }
    #bgPlusOut {
      @include respond-to($phoneLarge) {
        &:hover {
          background-color: #272121;
        }
      }
    }
  }
}
