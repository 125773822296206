@import "../global/variables.scss";
@import "../global/typography.scss";
@import "../global/mediaquery.scss";

@include respond-to($tablet-start2) {
  body {
    background-image: url("../img/fill-1.svg");
  }
}
.containerMobile {
  @include respond-to($phoneLarge) {
    padding: 0;
    width: 100%;
  }
  .contentBoxesOut {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
    .boxesIniziali {
      background-color: $brown;
      padding: 52px;
      transition: 0.4s;
      @include respond-to($phoneLarge) {
        margin-top: 0;
        margin-bottom: 20px;
      }
      min-height: 30em;
      @include respond-to($tablet-landscape2) {
        margin-top: -8em;
        max-width: 48%;
      }

      .boxIn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        max-height: 356px;
        text-align: center;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-weight: 500;
        }
      }
      .line {
        width: 120px;
        height: 2px;
        background: $white;
      }
      .textBox {
        width: 412px;
        height: 34px;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }
      a {
        background: transparent;
        border: 2px solid $white;
        color: $white;
        height: 54px;
        max-width: 315px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .instestazioneProdottiEvidenza,
  .intestazioneContattiHome,
  .intestazioneStorieVini,
  .intestazioneNewsHome {
    width: 100%;
    margin: 120px 0 30px 0;
    @include respond-to($tablet-start) {
      margin: 80px 0 30px 0;
    }
    position: relative;
    .cerchioDesktop {
      svg {
        width: 100%;
      }
      svg:last-child {
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 80px);
        max-width: 45px;
      }
    }
    .cerchioMobile {
      display: none;
    }
    @include respond-to($tablet-start) {
      .cerchioDesktop {
        display: none;
      }
      .cerchioMobile {
        width: 102px;
        height: 102px;
        display: block;
        margin: 0 auto;
        text-align: center;
        border: solid 4px #f8f2ed;
        border-radius: 50%;
        svg {
          max-width: 35px;
        }
      }
    }
    p {
      width: 100%;

      display: block;
      margin: 30px auto 68px auto;
      @include respond-to($tablet-start) {
        margin: 30px auto 30px auto;
      }
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $black;
    }
  }
  .intestazioneContattiHome {
    .cerchioDesktop {
      svg:last-child {
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 75px);
        max-width: 55px;
      }
    }
    @include respond-to($tablet-start) {
      .cerchioMobile {
        svg {
          max-width: 45px;
        }
      }
    }
  }
  .intestazioneStorieVini {
    .cerchioDesktop {
      svg:last-child {
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 65px);
        max-width: 60px;
      }
    }
    @include respond-to($tablet-start) {
      .cerchioMobile {
        svg {
          margin-top: 12px;
          max-width: 45px;
        }
      }
    }
  }
  .intestazioneNewsHome {
    .cerchioDesktop {
      svg:last-child {
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 65px);
        max-width: 55px;
      }
    }
    @include respond-to($tablet-start) {
      .cerchioMobile {
        svg {
          margin-top: 12px;
          max-width: 45px;
        }
      }
    }
  }
}
#bgPlusOut {
  &:hover {
    svg {
      path {
        fill: $black;
      }
    }
    background-color: $yellow;
    .line {
      background-color: $brown;
    }
    .textBox {
      color: $brown;
    }
    a {
      border: 2px solid $brown;
      color: $brown;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #bgPlus {
      background-image: url("../img/pattern-cleto.svg");
      transition: 0.4s;
    }
  }
  #bgPlus {
    background-image: url("../img/plus.svg");
    transition: 0.4s;
  }
}
#bgGrapsOut {
  svg {
    margin-bottom: 42px;
  }
  &:hover {
    svg {
      g {
        fill: $black;
      }
    }
    background-color: $red;
    .line {
      background-color: $brown;
    }
    .textBox {
      color: $brown;
    }
    a {
      border: 2px solid $brown;
      color: $brown;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #bgGraps {
      background-image: url("../img/boz-chiarlimodena.svg");
      transition: 0.4s;
    }
  }
  #bgGraps {
    background-image: url("../img/graps.svg");
    transition: 0.4s;
  }
}
.sezioneContatti {
  .row {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 120px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    @include respond-to($tablet-start2) {
      max-width: 1180px;
      padding: 0 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .boxSinistra {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 680px;
      background-color: #fcf7f2;

      padding-top: 151px;
      padding-left: 40px;
      padding-right: 150px;
      padding-bottom: 40px;
      @include respond-to($tablet-start) {
        width: 100%;
        min-height: 320px;
        min-height: auto;
        padding: 50px 40px;
        height: auto;
      }

      h2 {
        font-family: "Playfair Display", serif;
        font-size: 36px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $black;
        @include respond-to($tablet-start) {
          font-size: 30px;
        }
      }
      p {
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: $black;
        margin-top: 20px;
        margin-bottom: 40px;
        @include respond-to($tablet-start) {
          font-size: 14px;
          margin-bottom: 80px;
        }
      }
      a {
        width: 170px;
        height: 54px;
        border: solid 2px $black;
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: $black;
        background-color: transparent;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to($tablet-start) {
          width: 100%;
        }
        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
    .immagineDestra {
      padding: 0;
      img {
        height: 460px;
        width: 50vw;
        object-fit: cover;
        @include respond-to($tablet-start) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.sezioneVino {
  width: 100%;
  position: relative;
  margin-top: 200px;
  @include respond-to($tablet-start) {
    margin-top: 0px;
    .container {
      width: 100%;
      padding: 0;
      max-width: unset;
    }
  }
  .boxSinistra {
    width: 545px;
    height: 460px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: $brown;
    padding: 51px 113px 48px 42px;
    top: -100px;
    @include respond-to($tablet-start) {
      width: 100%;
      height: auto;
      display: block;
      position: unset;
      top: 0;
    }
    @include respond-to($phoneLarge) {
      padding-right: 30px;
      padding-left: 30px;
    }

    h2 {
      font-family: "Playfair Display", serif;
      font-size: 36px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;
    }
    p {
      font-family: "Rubik", sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $white;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    a {
      width: 170px;
      height: 54px;
      border: solid 2px $white;
      font-family: "Rubik", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      background-color: transparent;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: $white;
        color: $brown;
      }
      @include respond-to($tablet-start) {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
  .immagineDestra {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      @include respond-to($tablet-start) {
        width: 100%;
        height: auto;
      }
    }
  }
}
.sezioneNewsHome {
  .row {
    display: flex;
    .fasciaColorata {
      display: flex;
      width: 100%;
      height: 20px;
      background-color: #b3f4be;
      @include respond-to($tablet-start) {
        display: none;
      }
    }
    .boxSinistra {
      padding-left: 0;
      position: absolute;
      height: 460px;
      object-fit: cover;
      @include respond-to($tablet-start) {
        width: 100%;
        min-height: 320px;
        min-height: auto;
        padding: 50px 40px;
        height: auto;
      }
      img {
        width: 100%;
        height: 460px;
        object-fit: cover;
        @include respond-to($tablet-start) {
          height: 320px;
        }
      }
    }
    @include respond-to($tablet-start) {
      .boxSinistra {
        padding: 0;
        position: relative;
      }
    }
    .immagineDestra {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 480px;
      padding-left: 50px;
      @include respond-to($tablet-start) {
        height: auto;
        padding-top: 25px;
      }
      p {
        font-size: 18px;
        @include respond-to($tablet-start) {
          font-size: 14px;
        }
      }
      @include respond-to($tablet-start) {
        padding-top: 15px;
      }
      @include respond-to($phoneLarge) {
        padding-left: 15px;
        padding-right: 15px;
      }
      p:first-child {
        font-size: 18px;
        line-height: 1.56;
        margin-bottom: 0;
      }
      span {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #a2a2a2;
      }
      h2 {
        font-family: "Playfair Display", serif;
        font-size: 36px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $black;
        margin: 20px auto;
        line-height: 1.1;
        margin-bottom: 40px;
        @include respond-to($tablet-start) {
          font-size: 24px;
        }
      }
      p:nth-child(2) {
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: $black;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
    a {
      border: solid 2px $black;
      width: 160px;
      height: 54px;
      border: solid 2px #1f1f1f;

      font-family: "Rubik", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $black;
      background-color: transparent;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: $black;
        color: $white;
      }
      @include respond-to($tablet-start) {
        width: 100%;
        margin: 30px auto;
      }
    }
  }
}
.sezioneStorieVini {
  width: 100%;
  background-color: #fcf7f2;
  margin: 150px 0 0 0;
  @include respond-to($tablet-start2) {
    height: 680px;
    display: flex;
    margin: 150px auto;
    justify-content: center;
    align-items: center;
  }
  .row {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include respond-to($tablet-start) {
      padding: 0;
    }
    .boxSinistra {
      @include respond-to($tablet-start) {
        order: 2;
        margin-top: 50px;
        padding: 0;
      }
      .fasciaColorata {
        width: 100%;
        height: 20px;
        background-color: #ffd667;
        &__red {
          background-color: #dc2933;
        }
      }
      .primoBoxBianco {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 456px;
        background-color: $white;
        padding: 69px 40px 40px 40px;
        @include respond-to($phoneLarge) {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      h2 {
        font-family: "Playfair Display", serif;
        font-size: 36px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $black;
        margin: 20px auto;
        @include respond-to($tablet-start) {
          font-size: 24px;
        }
      }
      p {
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: $black;
        margin-top: 20px;
        margin-bottom: 40px;
        @include respond-to($tablet-start) {
          font-size: 14px;
        }
      }
      a {
        width: 170px;
        height: 54px;
        border: solid 2px $black;
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: $black;
        background-color: transparent;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-to($tablet-start) {
          width: 100%;
        }
        &:hover {
          background: $black;
          color: $white;
        }
      }
    }

    .immagineDestra {
      @include respond-to($tablet-start) {
        order: 1;
        margin-top: -130px;
      }
      text-align: center;
      img {
        width: auto;
        max-height: 677.3px;
      }
    }
  }
}

.ch-storie-vini {
  .sezioneStorieVini {
    margin-top: 250px;
  }
  @include respond-to($phoneLarge) {
    .divider {
      display: none;
    }
  }
}
