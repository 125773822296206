@import "../global/variables.scss";
@import "../global/typography.scss";
@import "../global/mediaquery.scss";

.noScroll {
  position: fixed;
  @include respond-to($tablet-start2) {
    overflow-y: scroll;
  }
  width: 100%;
}
.menuPrincipale {
  position: absolute;
  width: 100%;
  top: 34px;
  z-index: 99999999;

  padding-bottom: 30px;
  padding-top: 30px;
  @include respond-to($phoneLarge) {
    padding-bottom: 17px;
    padding-top: 17px;
  }

  .jf-flex {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: center;
    @include respond-to($phoneLarge) {
      height: 100%;
      align-items: center;
    }
  }
  .button_container {
    position: relative;
    height: 19px;
    width: 28px;
    z-index: 1000;
    cursor: pointer;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      .top {
        transform: translateY(7px) translateX(0) rotate(45deg);
        background: $white;
      }
      .middle {
        opacity: 0;
        background: $white;
      }

      .bottom {
        transform: translateY(-7px) translateX(0) rotate(-45deg);
        background: $white;
      }
    }

    span {
      background: $white;
      border: none;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.35s ease;
      cursor: pointer;
      &:nth-of-type(2) {
        top: 7px;
      }

      &:nth-of-type(3) {
        top: 14px;
      }
    }
  }
  .logo {
    z-index: 1000;
    svg {
      width: 150px;
      @include respond-to($phoneLarge) {
        width: 50px;
        height: 76px;
      }
      .cls-1 {
        fill: white;
      }
    }
  }

  .overlay {
    position: fixed;
    background-image: url("../img/img-background-menu.png");
    background-position: center right;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 998;
    display: none;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    @include respond-to($tablet-landscape2) {
      overflow: hidden;
      -webkit-flex-direction: column;
      flex-direction: column;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      flex-basis: 100%;
    }

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @include respond-to($tablet-start) {
        display: block;
        overflow-y: scroll;
      }
      @include respond-to($tablet-start2) {
        padding-top: 100px;
      }
      @include respond-to($phoneLarge) {
        padding-bottom: 200px;
      }
      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;

        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }
      }
    }
    .overlay-menu {
      font-size: 50px;
      font-weight: 400;
      text-align: center;
      font-family: "Rubik", sans-serif;
      @include respond-to($tablet-start) {
        margin-top: 100px;
        width: 100%;
        max-width: 100%;
        padding: 38px;
      }
      // @include respond-to($tablet-landscape2) {
      //     margin: 350px auto 0 auto;
      // }
      .contentBloccoMenu {
        .is-active .dropdownMenu {
          display: block;
        }
        p {
          @include respond-to($tablet-start2) {
            color: $white;
            text-decoration: none;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.71;
            letter-spacing: normal;
            text-align: left;
            display: block;
          }
          display: none;
        }
        @include respond-to($tablet-start) {
          padding: 0;
          &:nth-child(4) {
            .bloccoMenu {
              border-bottom: none;
            }
          }
          .dropdownMenu {
            display: none;
          }
        }
        .bloccoMenu {
          border-bottom: 1px solid $white;
          @include respond-to($tablet-start2) {
            border-top: 1px solid $white;
          }
          padding: 24px 0;
          @include respond-to($tablet-start2) {
            max-width: 250px;
            min-height: 280px;
            margin-bottom: 24px;
          }
          svg {
            transition: 0.3s all ease-in-out;
          }
          span {
            cursor: pointer;
            opacity: 1;
            font-size: 14px;
            display: block;
            text-align: left;
            color: $white;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: 0.3s all;
            @include respond-to($tablet-start2) {
              font-size: 12px;
              margin-bottom: 20px;
              opacity: 0.7;
              font-weight: bold;
              svg {
                display: none;
              }
            }
          }
          a {
            color: $white;
            text-decoration: none;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.71;
            letter-spacing: normal;
            text-align: left;
            display: block;
          }
        }
        .is-active {
          svg {
            transform: rotate(90deg);
          }
          .toggle {
            outline: none;
            padding-bottom: 24px;
          }
        }
      }
    }

    .bottomMenu {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      height: 10vh;
      padding: 0 30px;
      @include respond-to($tablet-start) {
        -webkit-flex-direction: column;
        flex-direction: column;
        align-items: start;
        padding: 0 38px;
      }
      .bottoniMenu {
        @include respond-to($tablet-start) {
          order: 2;
        }
        a {
          width: 84px;
          height: 36px;
          padding: 10px;
          opacity: 0.3;
          background: transparent;
          border: 1px solid $white;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.86;
          letter-spacing: normal;
          text-align: center;
          color: $white;
          margin-right: 30px;
          @include respond-to($tablet-start) {
            margin: 30px 0;
            padding: 0;
            display: block;
          }
        }
        a.active {
          width: 84px;
          height: 36px;
          background-color: $white;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.86;
          letter-spacing: normal;
          text-align: center;
          color: $black;
          opacity: 1;
        }
      }
      .socialMenu {
        @include respond-to($tablet-start) {
          order: 1;
        }
        a {
          margin-right: 1em;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    @keyframes fadeInRight {
      0% {
        opacity: 0;
        left: 20%;
      }
      100% {
        opacity: 1;
        left: 0;
      }
    }
    .divContentBloccoMenuMobile {
      display: none;
    }
    @include respond-to($tablet-start) {
      .divContentBloccoMenuMobile {
        display: block;
        margin: 100px 0 90px 0;
        width: 100%;
        .contentBloccoMenuMobile:first-child .bloccoMenu {
          border-top: 1px solid $white;
        }
        .contentBloccoMenuMobile {
          color: $white;
          padding: 0;
          text-decoration: none;
          overflow: hidden;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          display: block;
          .bloccoMenu {
            padding: 16px 0;
            margin: 0;
            border-bottom: 1px solid $white;
            a {
              color: $white;
              text-decoration: none;
              overflow: hidden;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.71;
              letter-spacing: normal;
              text-align: left;
              display: block;
            }
          }
        }
      }
    }
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $blu;
  font-size: 14px !important;
  opacity: 0.5;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $blu;
  font-size: 14px !important;
  opacity: 0.5;

  @include pfont;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $blu;
  font-size: 14px !important;
  @include pfont;
  opacity: 0.5;
}
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.5;
  font-size: 14px !important;
  @include pfont;
  color: $blu;
}

.jf-hamburger {
  width: 40px;
  height: 40px;
  color: $white;
}

.ch-center-menu {
  @include respond-to($tablet-start) {
    display: none;
  }
  ul {
    display: flex;
    margin-bottom: 0;
    a {
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #ffffff;
    }
    li {
      margin-right: 58px;
      position: relative;
      &:after {
        will-change: transform;
        transition: 0.3s ease;
        transform-origin: center;
        content: "";
        content: "";
        left: 0;
        width: 100%;
        position: absolute;
        bottom: -10px;
        background: white;
        height: 2px;

        transform: scale(0);
      }
      &.isActive {
        &:after {
          transform: scale(1);
        }
      }
      &:hover {
        &:after {
          transform: scale(1);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.headroom {
  transition: 0.3s ease;
}
.headroom--not-top {
  position: fixed;
  top: 0;
  background-color: #272121;
  .logo {
    svg {
      transition: 0.3s ease;
    }
  }
  @include respond-to($phoneLarge) {
    .logo {
      svg {
        height: 50px;
      }
    }
  }
}

.sottoTitoli {
  a {
    color: white;
  }
}

.logo-mobile {
  display: none;
  @include respond-to($phoneLarge) {
    display: block;
  }
}

.logo-desktop {
  @include respond-to($phoneLarge) {
    display: none;
  }
}
