@import "../global/variables.scss";

button {
  height: 64px;
  width: 220px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: $blu;
  border: 1px solid #bcbcbc;
  background-color: #ffffff;
  @include hFont;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  color: #666666;
  &.jf-yellow {
    border: 1px solid #fcc462;
    background-color: #fcc462;
    color: $blu;
  }
  &.jf-green {
    background-color: #75aa38;
    color: white;
    border: 1px solid #75aa38;
  }
}

.jf--box--btn {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  button {
    transition: 0.3s ease;
    &:hover {
      background: $yellow;
    }
    &.jf-yellow {
      &:hover {
        background: $blu;
        color: $yellow;
      }
    }
  }
}
