@import "../global/variables.scss";
@import "../global/typography.scss";
@import "../global/mediaquery.scss";

.masthead {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  height: 100vh;
  background: linear-gradient(
      to top,
      #{fade-out(#000, 0.8)} 0%,
      #{fade-out(#000, 0.9)} 15%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("../img/hero-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  .contentIntro {
    text-align: center;
    max-width: 470px;
    .stemma {
      display: block;
    }
    .un-nome-tante-espre {
      font-family: "Playfair Display", serif;
      font-weight: bold;
      font-size: 30px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
    h2 {
      max-width: 20rem;
      font-size: 1rem;
    }
    @media (min-width: 768px) {
      .un-nome-tante-espre {
        font-size: 48px;
      }
    }
    @media (min-width: 992px) {
      .un-nome-tante-espre {
        font-size: 48px;
      }
    }
  }
}
.jf-scroll-down {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  bottom: 220px;
  max-width: 200px;
  height: 50px;
  margin: 0 auto;
  span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
    &:nth-child(2) {
      animation-delay: -0.2s;
    }
    &:nth-child(3) {
      animation-delay: -0.4s;
    }
  }
  .primaPalla {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px $white solid;
    margin: 0 auto;
  }
  .secondaPalla {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 2px $white;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
    margin: 0 auto;
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
