@import "./global/mediaquery.scss";

.col-img {
  position: absolute;
  right: 0;
  width: 50vw;
  @media screen and (min-width: 1800px) {
    img {
      height: 500px;
      object-fit: cover;
    }
  }
  &__right {
    left: 0;
    right: auto;
  }
  @include respond-to($phoneLarge) {
    display: none;
  }
}
.ct-flex {
  display: flex;
  align-items: center;
  margin-top: 60px;
  &.ct-flex__right__last {
    &:after {
      @include respond-to($phoneLarge) {
        display: none !important;
      }
    }
  }
  @include respond-to($phoneLarge) {
    background-color: #3a0624;
    padding: 0;
    margin-top: 0;
    height: 94px;
    position: relative;
    &__half {
      height: 78px;
    }
    &.is-open {
      height: auto;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 36px;

      height: 1px;
      background: white;
      bottom: 1px;
      width: calc(100% - 72px);
    }
  }
  .container {
    @include respond-to($phoneLarge) {
      padding: 0;
    }
  }
  .col-md-6 {
    padding-right: 0;
    @include respond-to($phoneLarge) {
      padding: 0;
    }
  }
  &.ct-flex__right {
    .col-md-6 {
      padding-left: 0;
      padding-right: 15px;
      @include respond-to($phoneLarge) {
        padding: 0;
      }
    }
  }
}

.two-col-purple {
  background-color: #3a0624;
  height: 680px;
  padding-top: 53px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 46px;
  position: relative;
  @include respond-to($tablet-start) {
    height: 500px;
  }
  @include respond-to($phoneLarge) {
  }
  .d-mobile {
    @include respond-to($phoneLarge) {
      width: 100vw;
      transform: translateX(-36px);
      margin-top: 30px;
    }
  }
  @include respond-to($phoneLarge) {
    height: auto;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  h3 {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 30px;
    svg {
      display: none;
    }
    @include respond-to($tablet-start) {
      font-size: 24px;
    }
    @include respond-to($phoneLarge) {
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        display: block;
      }
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    @include respond-to($phoneLarge) {
      margin-top: 36px;
    }
  }
  a {
    width: 160px;
    height: 54px;
    border: solid 2px #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    position: absolute;
    bottom: 46px;
    left: 40px;
    color: #ffffff;
    background: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    &:hover {
      background: white;
      color: #3a0624;
    }
    @include respond-to($phoneLarge) {
      position: relative;
      width: 100%;
      left: auto;
      bottom: auto;

      margin-top: 30px;
    }
  }
}

.scrolldown {
  margin-top: 60px;
  @include respond-to($phoneLarge) {
    display: none;
  }
}

.container-storia-singola {
  margin-top: 70px;
  p {
    font-size: 18px;
    font-family: "Rubik", sans-serif;
  }
}

.storia-slider {
  @include respond-to($phoneLarge) {
    height: auto;
    flex-direction: column;
    &:after {
      display: none;
    }
  }
  .wrapper-slider-img {
    @include respond-to($phoneLarge) {
      margin-top: -1px;
    }
    img {
      @media (min-width: 1800px) {
        height: 500px;
        object-fit: cover;
      }
    }
  }
  .two-col-purple {
    h3 {
      margin-top: 0;
      span {
        display: block;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #ffffff;
        margin-top: 30px;
      }
    }
  }
  @include respond-to($phoneLarge) {
    height: auto;
    flex-direction: column;
    .slick-dots {
      bottom: 0;
    }
    .two-col-purple {
      padding: 50px;
      padding-bottom: 75px;
      padding-top: 0;
    }
    h3 {
      font-size: 24px;
    }
    .col-img {
      display: block;
      position: relative;
      width: 100%;
      height: 320px;
    }
  }
}

.ct-next-prev {
  margin-top: 120px;
  margin-bottom: 70px;
  @include respond-to($phoneLarge) {
    margin-bottom: 120px;
    margin-top: 0;
  }
  a {
    display: flex;

    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    display: flex;
    align-items: center;
    span {
      font-family: "Playfair Display", serif;
      font-size: 36px;
      @include respond-to($phoneLarge) {
        font-size: 24px;
      }
    }
    @include respond-to($phoneLarge) {
      display: flex;
      flex-direction: column;
      align-content: center;
    }

    svg {
      margin-right: 40px;
      @include respond-to($phoneLarge) {
        order: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
  .next {
    justify-content: flex-end;
    @include respond-to($phoneLarge) {
      span {
        order: 1;
      }
    }
    svg {
      transform: rotate(180deg);
      transform-origin: center;
      margin-left: 40px;
      margin-right: 0;
      @include respond-to($phoneLarge) {
        order: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.wrapper-slide-text {
  width: 100%;
  height: 100%;
  .slick-dots {
    position: absolute;
    bottom: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: center;
    margin-bottom: 0;
    > li:only-child {
      display: none;
    }
    @include respond-to($phoneLarge) {
      bottom: -40px;
    }
    li {
      margin-right: 20px;
      opacity: 0.5;
      width: 9px;
      height: 9px;
      background-color: #ffffff;
      position: relative;
      border-radius: 50%;
      &.slick-active {
        opacity: 1;
      }
    }
    button {
      width: 100%;
      height: 100%;
      text-indent: -4000px;
      bottom: auto;
      right: auto;
      left: auto;
      border: none;
      background: none;
    }
  }
}

/* TENUTE */

.hero-bottom {
  transform: translateY(90px);
  z-index: -9;

  img {
    @include respond-to($phoneLarge) {
      height: 460px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.two-col-purple__tenute {
  h3 {
    @include respond-to($phoneLarge) {
      font-size: 24px;
    }
  }
}

.tenute-m-b {
  margin-bottom: 100px;
}

.storia-slider {
  .col-img {
    right: auto;
    left: 0;
  }
  .col-md-6 {
    padding-right: 15px;
    padding-left: 0;
    @include respond-to($phoneLarge) {
      padding: 0;
    }
  }
  &.chi-siamo-slider,
  &__right {
    .col-img {
      right: 0;
      left: auto;
    }

    .col-md-6 {
      padding-left: 15px;
      padding-right: 0;
      @include respond-to($phoneLarge) {
        padding: 0;
      }
    }
  }
}

.chi-siamo-slider {
  @include respond-to($phoneLarge) {
    background: none;
  }
  .two-col-purple {
    @include respond-to($phoneLarge) {
      padding-top: 50px;
    }
  }
}

.inner-content {
  @include respond-to($phoneLarge) {
    display: none;
  }
}

.wrapper-accordion {
  @include respond-to($phoneLarge) {
    margin-bottom: -100px;
    .ct-flex {
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  &__noMargin {
    margin-bottom: 100px;
  }
}
