@import "./global/mediaquery.scss";

.text-intro {
  margin-top: 75px;
  margin-bottom: 90px;
}

.ct-news-filter {
  @include respond-to($phoneLarge) {
    // width: 150vw;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.container-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 84px;
  overflow: visible;
  z-index: 9999;
  background: white;
  padding-top: 10px;
  padding-bottom: 0;
  @include respond-to($phoneLarge) {
    .col-12 {
      overflow: scroll;
      -webkit-overflow-scrolling: auto;
    }
    .ct-news-filter {
      -webkit-overflow-scrolling: auto;
      overflow: scroll;
      justify-content: flex-start;
      width: 200vw;
    }
  }
}

.news-container {
  margin-top: 40px;
  @include respond-to($phoneLarge) {
    padding: 0;
    .col-12 {
      padding: 0;
    }
  }
}

.news-preview {
  background: #fcf7f2;
  margin-bottom: 40px;
  &__dark {
    background-color: #272121;
    padding-bottom: 40px;
    .news-preview--center {
      p {
        color: white !important;
      }
      h4 {
        color: white !important;
      }
      a {
        color: white;
        border: 2px solid white;
        width: 127px;
        text-transform: uppercase;
        @include respond-to($phoneLarge) {
          margin-top: 30px;
          margin-block-end: 20px;
        }
      }
    }
  }
  &__eventi {
    border-top: 36px solid #b3f4be;
  }
  &__rassegna {
    border-top: 36px solid #ade6ff;
  }
  &__riconoscimenti {
    border-top: 36px solid #ffcb98;
  }
}

.news-preview--top {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  .date {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #a2a2a2;
  }
  .tag {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #565656;
    text-transform: uppercase;
  }
}
.news-preview--center {
  padding-left: 25px;
  padding-right: 25px;
  h4 {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    margin-top: 24px;
    margin-bottom: 30px;
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: black;
  }
  button,
  a {
    height: 54px;
    border: solid 2px #1f1f1f;
    width: 100%;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
    margin-top: 70px;
    margin-bottom: 30px;
    background: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

.news-preview--img {
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.news-singola-top {
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
}

.container-news-singola {
  max-width: 1090px;
  .date {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #a2a2a2;
  }
  .badge {
    width: 159px;
    height: 37px;
    border-radius: 18.5px;
    background-color: #ffcb98;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }
}

.container--content-news {
  h1 {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: normal;
    margin-bottom: 50px;
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
  }
}

.news-singola-img {
  margin-top: 50px;
  margin-bottom: 50px;
  img {
    @include respond-to($phoneLarge) {
      height: 300px;
      object-fit: cover;
    }
  }
}

.container--related {
  @include respond-to($phoneLarge) {
    padding: 0;
    .col-12 {
      padding: 0;
    }
  }
  h2 {
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    margin-bottom: 40px;
    color: black;
  }
}

.ch-storie-vini {
  .ch-hero__dark {
    @include respond-to($phoneLarge) {
      height: auto;
      padding: 50px 0;
      padding-bottom: 100px;
    }
  }
}
