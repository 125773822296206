@import "./global/mediaquery.scss";

.ch-prodotti-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    margin-right: 50px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include respond-to($phoneLarge) {
      margin-right: 40px;
    }
    a {
      font-family: "Rubik", sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #1f1f1f;
      display: block;
      @include respond-to($phoneLarge) {
         font-size: 16px;
      }
    }
    &.isActive {
      a {
        font-weight: 500;
        text-decoration: underline;
      }
      &:before {
        content: "";
        width: 100%;
        height: 2px;
        background: #1f1f1f;
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }
  }
}
