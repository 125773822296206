@import "./global/mediaquery.scss";

.ch-hero {
  height: 545px;
  width: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  &_bottom {
    margin-bottom: -91px;
    z-index: -1;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  &__full {
    height: 90vh;
  }
  &__noShadow {
    &:before {
      display: none;
    }
  }
  &__dark {
    background-color: #272121;
    p {
      color: #ffffff;
      strong {
        font-size: 14px;
        font-weight: 500;
        margin-top: 50px;
      }
    }
    h2 {
      font-family: "Playfair Display", serif;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 40px;
    }
    &:before {
      display: none;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.58;
    background-image: linear-gradient(to bottom, black, rgba(62, 62, 62, 0));
    left: 0;
    top: 0;
  }
  svg {
    transform: translateY(31px);
  }
  h1 {
    font-family: "Playfair Display", serif;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 999;
    @include respond-to($phoneLarge) {
      font-size: 30px;
      max-width: 50%;
      br {
        display: none;
      }
    }
  }
}

.ch-hero-oval {
  background-color: white;
  width: 120px;
  height: 60px;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &__product {
    left: 50%;
    transform: translateX(-60px);
    &:before {
      //display: none;
    }
  }
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    width: 180px;
    height: 90px;
    border-radius: 50% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    bottom: 0;

    @include respond-to($phoneLarge) {
      // display: none;
    }
  }
  &__slider {
    left: 50%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 100px; */
    transform: translateX(-60px);
    svg {
      transform: translateY(20px);
    }
  }
}

.ch-hero-bottom {
  margin-top: 120px;

  margin-bottom: 70px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #1f1f1f;
  @include respond-to($phoneLarge) {
    font-size: 14px;

    br {
      display: none;
    }
  }
}

.ch-hero--center {
  padding-top: 20px;
  img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0;
    @include respond-to($phoneLarge) {
      position: relative;
      height: 320px;
      order: 2;
    }
  }
  @include respond-to($phoneLarge) {
    flex-direction: column;

    height: auto;
    .col-12 {
      padding: 0;
    }
    .container {
      padding: 0;
    }
  }
  .ch-hero--mention {
    padding-top: 90px;
    padding-bottom: 40px;
    padding-left: 31px;
    padding-right: 31px;
    background: white;
    width: 100%;
    border-top: 20px solid #ffd667;
    transform: translateY(-180px);
    @include respond-to($phoneLarge) {
      transform: none;
    }
    h3 {
      font-family: "Playfair Display", serif;
      font-size: 36px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      color: black;
      margin-bottom: 20px;
      margin-top: 0;
    }
    p {
      font-family: "Rubik", sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;

      color: black;
      padding-bottom: 50px;
    }
    button {
      width: 127px;
      height: 54px;
      border: solid 2px #1f1f1f;
      font-family: "Rubik", sans-serif;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1f1f1f;
    }
  }
  @include respond-to($tablet-start) {
    padding-top: 0;
  }
}
