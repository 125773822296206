$Xsmall-phone: "(max-width: 320px)";
$small-phone: "(max-width: 375px)";
$phone: "(max-width: 480px)";
$phoneLarge: "(max-width: 767px)";
$phoneSmall: "(max-width: 375px)";
$tablet-start: "(max-width: 991px)";
$tablet-start2: "(min-width: 992px)";
$tablet-portrait: "(max-width: 768px)";
$tablet-landscape2: "(min-width: 769px)";
$tablet-landscape: "(min-width: 769px) and (max-width: 1024px)";
$till-tablet-landscape: "(max-width: 1023px)";
$till-large: "(max-width: 1366px)";
$tablet-horizontal: " (max-width: 1024px) ";
$true-tablet-landscape: "(min-width: 769px) and (max-width: 1024px) and (max-height: 992px)";
$large-desktop: "(min-width: 1200px)";
$x-large-desktop: "(min-width: 1440px)";
$medium-desktop: "(max-width: 1280px)";
$non-retina: "screen and (-webkit-max-device-pixel-ratio: 1)";
$ipad-pro-portrait: "(min-width: 1024px)  and (max-height: 1366px)and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)  ";

$retina: "(min--moz-device-pixel-ratio: 1.5), 
                    (-o-min-device-pixel-ratio: 3/2), 
                    (-webkit-min-device-pixel-ratio: 1.5), 
                    (min-device-pixel-ratio: 1.5), 
                    (min-resolution: 144dpi), 
                    (min-resolution: 1.5dppx)";

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}
