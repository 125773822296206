@import "./variables";
@import "./mediaquery";

.container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  @include respond-to($tablet-landscape) {
    max-width: 991px;
    padding: 0 70px;
  }
  @include respond-to($phoneLarge) {
    padding: 0 20px;
  }
}
.m-b-120 {
  margin-bottom: 120px;
}
.jf-full {
  width: 100%;
}
// #app {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }
ul {
  padding-left: 0;
  list-style-type: none;
}
.intro-center {
  margin-top: 80px;
  margin-bottom: 100px;
}
.m-t-100 {
  margin-top: 100px;
  @include respond-to($phoneLarge) {
    margin-bottom: 80px;
  }
}

.p-l-55 {
  padding-left: 55px;
  @include respond-to($tablet-start) {
    padding-left: 0;
  }
}

.p-r-55 {
  padding-right: 55px;
  @include respond-to($phoneLarge) {
    padding-right: 0;
  }
}
// body {
//   overflow-x: hidden;
//   -webkit-font-smoothing: antialiased;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
// }

img {
  width: 100%;
}
// #app {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }
.main-container {
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: calc(100vh - 80px);
  overflow: hidden;
}

.d-mobile {
  display: none;
  @include respond-to($phoneLarge) {
    display: block;
  }
}
.jf-block {
  display: block;
}
a {
  text-decoration: none !important;
}

strong {
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid {
  @media (max-width: 991.98px) {
    padding: 0;
  }
}

.jf-triangle {
  position: relative;

  padding: 1em;
  box-sizing: border-box;
  background: $yellow;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  top: 50px;

  z-index: 99999;
  border-radius: 2px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -2em;
    left: 50%;
    box-sizing: border-box;

    border: 1em solid black;
    border-color: transparent transparent $yellow $yellow;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }
}
