@import "../global/variables.scss";

.lk-result__body {
  color: $lightBlack;
  &__container {
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width: 991.98px) {
      padding-top: 27px;
      padding-bottom: 27px;
    }
  }

  &__title {
    font-size: 48px;
    margin-bottom: 0px;
    @media (max-width: 991.98px) {
      font-size: 36px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;

    column-gap: 38px;
    column-count: 2;
    @media (max-width: 991.98px) {
      column-count: 1;
    }
    &--checklist {
      display: none;
    }
  }

  &__list-container {
    margin: 0;
    margin-top: 30px;
    @media (max-width: 991.98px) {
      margin-top: 27px;
    }
  }

  &__list-item {
    display: flex;
    margin-bottom: 0;
    @media (max-width: 991.98px) {
      margin-bottom: 30px;
    }
    &__text {
      font-size: 14px;
      margin-left: 10px;
      text-decoration: line-through;
      flex-basis: 90%;

      margin-bottom: 20px;
      color: #97abbf;
      @media (min-width: 1023px) {
        min-height: 55px;
        margin-bottom: 0;
      }
      @media (max-width: 991.98px) {
        font-size: 18px;
        margin-bottom: 0;
      }
    }

    &.is-checked {
      .lk-result__body__icon-image--checked {
        display: block;
      }

      .lk-result__body__icon-image--unchecked {
        display: none;
      }

      .lk-result__body__list-item__text {
        text-decoration: none;
        color: $lightBlack;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-label {
    text-transform: uppercase;
    margin-top: 10px;
    text-align: center;
    @media (max-width: 991.98px) {
      font-size: 21px;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    flex-basis: 10;
  }

  &__icon-image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--checked {
      display: none;
    }

    &--unchecked {
      display: block;
    }
  }
}

.lk-result__cancel-button {
  display: none;
  padding: 16px;
  margin-top: 30px;
  .lk-btn__icon {
    filter: grayscale(1) brightness(5);
  }

  &__label {
    display: none;
  }
}

.lk-result__customize-button {
  .active-icon {
    display: none;
  }

  &__label {
    .active-text {
      display: none;
    }
  }
}

.lk-option-checkbox {
  display: none;

  &:checked + label {
    .lk-option-checkbox__icon--add {
      display: none;
    }
    .lk-option-checkbox__icon--remove {
      display: block;
    }
  }
}

.lk-option-checkbox__label {
  margin-bottom: 0;
  img {
    width: 30px;
  }
  cursor: pointer;

  .lk-option-checkbox__icon--add {
    display: block;
  }
  .lk-option-checkbox__icon--remove {
    display: none;
  }
}

.lk-step__body {
  &.col-xl-10 {
    margin: 0 auto;
    @media (max-width: 991.98px) {
      padding: 0;
    }
  }
}

*:focus {
  outline: none !important;
}
