.menu-back {
  position: absolute;
  left: -56px;
}

.menuPrincipale__prodotto {
  .container {
    position: relative;
  }
}
