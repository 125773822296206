@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700,400,700i|family=Lato:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,500,700&display=swap");

@mixin fluid-font-size(
  $responsive,
  $min-font-size,
  $max-font-size,
  $fallback: false
) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == "vh", "height", "width");
  $min-breakpoint: $min-font-size / $responsive-unitless * 100;
  $max-breakpoint: $max-font-size / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min-font-size;
  }

  @media (min-#{$dimension}: #{$max-breakpoint}) {
    font-size: $max-font-size;
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

.bl-leaflet {
  @include fluid-font-size(1.2vw, 11px, 11px, 11px);
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  letter-spacing: 3px;
}

.bl-heading {
  @include fluid-font-size(3vw, 48px, 64px, 32px);
  font-family: "Playfair Display", serif;
  line-height: 1;
}

.bl-paragraph {
  @include fluid-font-size(2vw, 16px, 18px, 18px);
  font-family: "Lato", sans-serif;
}
