@import "./global/mediaquery.scss";

.ct-metodo--oval {
  width: 140px;
  height: 140px;
  border: solid 4px #f8f2ed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 99;
  background: white;
}

.ct-metodo-intro {
  display: flex;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    width: 50%;
    left: 0;
    top: 70px;
    height: 2px;
    background: #f8f2ed;
    @include respond-to($phoneLarge) {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 50%;
    right: 0;
    top: 70px;
    height: 2px;
    background: #f8f2ed;
    @include respond-to($phoneLarge) {
      display: none;
    }
  }
}

.ct-metodo {
  margin-top: 100px;
  h4 {
    margin-top: 30px;
    font-size: 24px;
    color: #1f1f1f;
  }
}

.ch-intro__red {
  border-top: 36px solid #dc2933;
  padding-top: 100px;
  padding-bottom: 100px;
  h3 {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 40px;
  }
  p {
    font-family: Rubik;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1f1f1f;
  }
}
.ct-related {
  .btn {
    width: 227px;
    height: 54px;
    background-color: #272121;
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    @include respond-to($phoneLarge) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .text-left {
    @include respond-to($phoneLarge) {
      margin-left: auto;
      margin-right: auto;
      width: 297px;
    }
  }
}

.ch-hero__cantina {
  display: flex;
  flex-direction: column;
  svg {
    width: 340px;
    height: 200px;
    @include respond-to($phoneLarge) {
      width: 100%;
    }
  }
  h1 {
    margin-bottom: 100px;
  }
  h2 {
    font-family: "Playfair Display", serif;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 999999;
    @include respond-to($phoneLarge) {
      font-size: 30px;
    }
  }
}

.ct-metodo--text {
  height: 460px;
  background-color: #fcf7f2;
  width: 50vw;
  position: absolute;
  right: 0;
  padding: 64px;
  @include respond-to($phoneLarge) {
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 37px;
    padding-top: 52px;
    br {
      display: none;
    }
  }
  p {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 70px;
    @include respond-to($phoneLarge) {
      font-size: 24px;
    }
  }
  .btn {
    width: 160px;
    height: 54px;
    border: solid 2px #1f1f1f;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
  }
}

.ct-metodo--two-col {
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  margin-top: 100px;
  @include respond-to($phoneLarge) {
    .container,
    .col-12 {
      padding: 0;
    }
  }
  @include respond-to($phoneLarge) {
    flex-direction: column;
  }
  .img-metodo {
    width: 544px;
    height: 679px;
    object-fit: cover;
    transform: translateX(12px);
    @include respond-to($phoneLarge) {
      width: 100%;
      height: 320px;
      transform: none;
    }
  }
}
