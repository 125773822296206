.prenota {
  background-color: #272121;
  width: 100%;
  margin-top: 90px;
  margin-bottom: 180px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 500;

  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
